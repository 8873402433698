import { TSortingDropdownItems } from '../SortingDropdown/types';

export enum ArticlesSortingDropdownKey {
  Newest = 'Newest',
  Oldest = 'Oldest',
  MostTimePoints = 'MostTimePoints',
  RecommendedArticles = 'HomepageRecommendedArticles',
}

export const ARTICLES_SORTING_DROPDOWN_ITEMS: TSortingDropdownItems[] = [
  {
    name: ArticlesSortingDropdownKey.Newest,
    label: 'Newest',
  },
  {
    name: ArticlesSortingDropdownKey.Oldest,
    label: 'Oldest',
  },
  {
    name: ArticlesSortingDropdownKey.MostTimePoints,
    label: 'Most Time Points',
  },
  {
    name: ArticlesSortingDropdownKey.RecommendedArticles,
    label: 'Recommended',
  },
];
