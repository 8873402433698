import React, { FC } from 'react';
import cx from 'classnames';

type THeroSlideWrapperProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

const HeroSlideWrapper: FC<THeroSlideWrapperProps> = ({ children, className, onClick }) => (
  <div
    className={cx(
      'flex items-center py-8 md:py-16 px-14 xl:px-24 gap-x-14 xl:gap-x-20 bg-white-900 rounded-[24px]',
      className,
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

export default HeroSlideWrapper;
