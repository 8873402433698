import { gql } from '@apollo/client';
import { ARTICLE_CARD_FIELDS } from '../../TerritoryArticlesSection/queries/articleCardDataFragment';

const getHomepagePostsFeed = gql`
  ${ARTICLE_CARD_FIELDS}
  query getSortedArticles($where: ArticleWhereInput, $skip: Int!, $take: Int!, $sortBy: ArticlesOrder) {
    getSortedArticles(where: $where, skip: $skip, take: $take, sortBy: $sortBy) {
      articles {
        ...ArticleCardFields
      }
      articlesCount
    }
  }
`;

export default getHomepagePostsFeed;
