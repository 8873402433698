import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { HomepageHeroSlideName } from './constants';
import { EOYSlide } from './EOYSlide';
import { GardenersSlide } from './GardenersSlide';
import { TerritoryDiscoverySlide } from './TerritoryDiscoverySlide';

const CAROUSEL_PROPS: Settings = {
  dots: true,
  dotsClass: 'slick-dots slick-dots-hero',
  className: 'mx-0',
  infinite: true,
  speed: 500,
  rows: 1,
  swipe: true,
  draggable: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
};

const HomepageHero = () => {
  const { sendEvent } = useAnalytics();

  const sendSlideViewEvent = (currentSlideIndex: number) => {
    const { slideName } = SLIDES[currentSlideIndex];
    sendEvent('hero_slide_view', { slide_index: currentSlideIndex, slide_name: slideName });
  };
  const sendSlideButtonClickEvent = (slideName: HomepageHeroSlideName) => () => {
    sendEvent('hero_slide_button_click', { slide_name: slideName });
  };

  const SLIDES = [
    {
      slideName: HomepageHeroSlideName.gardeners,
      element: <GardenersSlide onButtonClick={sendSlideButtonClickEvent(HomepageHeroSlideName.gardeners)} />,
    },
    {
      slideName: HomepageHeroSlideName.territoryDiscovery,
      element: (
        <TerritoryDiscoverySlide onButtonClick={sendSlideButtonClickEvent(HomepageHeroSlideName.territoryDiscovery)} />
      ),
    },
    {
      slideName: HomepageHeroSlideName.eoy,
      element: <EOYSlide onButtonClick={sendSlideButtonClickEvent(HomepageHeroSlideName.eoy)} />,
    },
  ];

  return (
    <Slider {...CAROUSEL_PROPS} afterChange={sendSlideViewEvent}>
      {SLIDES.map(({ slideName, element }) => (
        <div key={slideName}>{element}</div>
      ))}
    </Slider>
  );
};
export default HomepageHero;
