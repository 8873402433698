import React, { FC } from 'react';
import { useMutation } from '@apollo/client';
import { TArticleCardProps } from '../ArticleCard/ArticleCard';
import { PostCard } from './PostCard';
import { MAX_PINNED_ARTICLES, MAX_PINNED_ARTICLES_PER_TERRITORY } from '../TerritoryArticlesSection/constants';
import { showNotification } from '../NotificationBanner/showNotification';
import { updateTerritoryPinnedAtQuery } from '../Territory/mutations/updateTerritoryPinnedAtQuery';
import { getTerritoryPinnedArticles } from '../TerritoryContent/queries/getTerritoryPinnedArticles';
import { useAnalytics } from '../AnalyticsProvider/AnalyticsProvider';
import { ArticleCardPurpose } from '../ArticleCard/constants';

type TPostCardContainerProps = {
  article: TArticleCardProps;
  canManagePinnedArticles?: boolean;
  isPinningEnabled?: boolean;
  forceMobileView?: boolean;
  showTerritory?: boolean;
  purpose: ArticleCardPurpose;
};

export const PostCardContainer: FC<TPostCardContainerProps> = ({
  article,
  canManagePinnedArticles,
  isPinningEnabled,
  forceMobileView = false,
  showTerritory,
  purpose,
}) => {
  const [updateTerritoryPinnedAt] = useMutation(updateTerritoryPinnedAtQuery);
  const { sendEvent } = useAnalytics();
  const onPostClick = () => {
    sendEvent('post_card_click', { purpose, post_id: article.id });
  };

  const toggleTerritoryPin = async () => {
    try {
      await updateTerritoryPinnedAt({
        variables: {
          id: article?.id,
          data: {
            territoryPinnedAt: article?.territoryPinnedAt ? null : new Date().toISOString(),
          },
        },
        refetchQueries: [getTerritoryPinnedArticles],
      });
    } catch (e) {
      if ((e as any).message.includes(MAX_PINNED_ARTICLES)) {
        showNotification({
          message: `You can only have ${MAX_PINNED_ARTICLES_PER_TERRITORY} Posts pinned.`,
          type: 'error',
          title: 'You’re out of Pins',
          displayIcon: false,
        });
      }
    }
  };

  return (
    <PostCard
      key={article.id}
      article={article}
      canManagePinnedArticles={canManagePinnedArticles}
      isPinningEnabled={isPinningEnabled}
      onPinClick={toggleTerritoryPin}
      forceMobileView={forceMobileView}
      showTerritory={showTerritory}
      onClick={onPostClick}
    />
  );
};
