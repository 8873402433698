import React, { FC } from 'react';
import Image from 'next/image';
import { THeroSlideProps } from './types';
import HeroSlideWrapper from './HeroSlideWrapper';
import { TERRITORY_DISCOVERY_LINK } from './constants';

type TTerritoryDiscoverySlideProps = THeroSlideProps;

export const TerritoryDiscoverySlide: FC<TTerritoryDiscoverySlideProps> = ({ onButtonClick }) => {
  const onClick = () => {
    onButtonClick();
    window.location.href = TERRITORY_DISCOVERY_LINK;
  };
  return (
    <HeroSlideWrapper
      className="!bg-transparent relative justify-between px-5 md:px-14 xl:px-24 cursor-pointer h-[430px]"
      onClick={onClick}
    >
      <div className="absolute inset-0 rounded-[24px] overflow-hidden md:hidden">
        <Image
          src="/images/territory-discovery-mobile.png"
          alt="Territory Discovery background"
          layout="fill"
          objectFit="contain"
          objectPosition="center"
          priority
        />
      </div>

      <div className="absolute inset-0 rounded-[24px] overflow-hidden hidden md:block xl:hidden">
        <Image
          src="/images/territory-discovery-tablet.png"
          alt="Territory Discovery background"
          layout="fill"
          objectFit="contain"
          objectPosition="center"
          priority
        />
      </div>

      <div className="absolute inset-0 rounded-[24px] overflow-hidden hidden xl:block">
        <Image
          src="/images/territory-discovery-desktop.png"
          alt="Territory Discovery background"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          priority
        />
      </div>
    </HeroSlideWrapper>
  );
};
