import React, { useState } from 'react';
import { getArticlesCardData } from '../ArticleCard/services/getArticlesCardData';
import getHomepagePostsFeed from './queries/getHomepagePostsFeed';
import { ArticleCardPurpose } from '../ArticleCard/constants';
import { ARTICLES_SORTING_DROPDOWN_ITEMS, ArticlesSortingDropdownKey } from './constants';
import { SortingDropdown } from '../SortingDropdown/SortingDropdown';
import { SectionHeader } from '../Section/SectionHeader';
import { SectionListResults } from '../SectionListResults/SectionListResults';
import { Section } from '../Section/Section';
import { PostCardContainer } from '../PostCard/PostCardContainer';
import { PostCardLoader } from '../PostCard/PostCardLoader';
import { useT2Query } from '../../hooks/useT2Query';
import { ArticleType } from '../Article/types';

const NUMBER_OF_ARTICLES = 9;
const DEFAULT_SORT_BY = ArticlesSortingDropdownKey.RecommendedArticles;

const HomepagePostsContainer = () => {
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_BY as string);
  const { data, loading, error, called, fetchMore } = useT2Query(getHomepagePostsFeed, {
    variables: {
      where: {
        type: { equals: ArticleType.Article },
        NOT: { territory: null },
      },
      skip: 0,
      take: NUMBER_OF_ARTICLES,
      sortBy,
      infiniteScroll: true,
      placement: ArticleCardPurpose.homepagePosts,
    },
    notifyOnNetworkStatusChange: true,
  });

  const onSortByChange = (sortBy: string) => {
    setSortBy(sortBy);
  };

  const articles = getArticlesCardData(data?.getSortedArticles?.articles || []);

  if (error) {
    return null;
  }

  const containerClassName = 'w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4';

  const results = (
    <section className={containerClassName}>
      {articles?.map((article) => (
        <PostCardContainer
          key={article.id}
          article={article}
          purpose={ArticleCardPurpose.homepagePosts}
          showTerritory
        />
      ))}
    </section>
  );

  const resultsPlaceholder = (
    <section className={containerClassName}>
      {Array.from({ length: NUMBER_OF_ARTICLES }, (_, index) => (
        <PostCardLoader key={`loader-${index}`} />
      ))}
    </section>
  );

  return (
    <Section
      header={
        <SectionHeader
          title="Posts"
          leftSide={
            <SortingDropdown
              items={ARTICLES_SORTING_DROPDOWN_ITEMS}
              onSortByChange={onSortByChange}
              purpose={ArticleCardPurpose.homepagePosts}
              defaultSortBy={sortBy}
              dropdownLabelClassName="!text-sm !text-t2-neutral-grey08 !bg-t2-neutral-grey03"
            />
          }
        />
      }
    >
      <SectionListResults
        results={results}
        resultsPlaceholder={resultsPlaceholder}
        loading={loading}
        data={articles}
        resultsCount={data?.getSortedArticles?.articlesCount}
        take={NUMBER_OF_ARTICLES}
        called={called}
        fetchMore={fetchMore}
      />
    </Section>
  );
};

export default HomepagePostsContainer;
