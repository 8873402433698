import { gql } from '@apollo/client';
import { articleCommonData } from '../../Article/queries/articleCommonData';

export const getTerritoryPinnedArticles = gql`
  query articles($territoryId: ID!) {
    articles(
      where: {
        territory: { id: { equals: $territoryId } }
        territoryPinnedAt: { not: { equals: null } }
        type: { equals: ARTICLE }
      }
    ) {
      ${articleCommonData}
    }
  }
`;
