import React from 'react';
import composeFp from 'lodash/fp/compose';
import { NextSeo } from 'next-seo';
import Container from '../components/Container/Container';
import { withApolloData } from '../services/apolloClient/withApolloData';
import Navbar from '../components/Navbar';
import { behindFeatureFlag } from '../components/FeatureFlags/getServerSidePropsDecorators/behindFeatureFlag';
import Footer from '../components/Footer';
import { useNavbarHeight } from '../components/Navbar/hooks/useNavbarHeight';
import HomepageHero from '../components/HomepageHero/HomepageHero';
import HomepagePostsContainer from '../components/HomepagePosts/HomepagePostsContainer';
import HomepagePromptsContainer from '../components/HomepagePrompts/HomepagePromptsContainer';
import { HomepageRecommendedPostsContainer } from '../components/HomepageRecommendedPosts/HomepageRecommendedPostsContainer';
import { getFeatureFlag } from '../components/FeatureFlags/getFeatureFlag';
import { BelowTheFold } from '../components/SsrContext/SsrContext';
import { logVisit } from '../services/visits/logVisit';
import { TerritoryDiscoverySection } from '../components/TerritoryDiscoverySection/TerritoryDiscoverySection';
import { TerritorySortBy } from '../components/TerritoryDiscovery/constants';

const HomePage = () => {
  const navbarHeight = useNavbarHeight();
  const isRecommendedPostsFeatureEnabled = getFeatureFlag('recommendedPosts');

  return (
    <>
      <NextSeo title="Home" />
      <div className="min-h-fitScreen bg-white-100">
        <Navbar themeVariant="light" />
        <main className="pb-24 md:pb-48" style={{ paddingTop: navbarHeight }}>
          <Container className="md:grid-cols-12 md:gap-x-3">
            <section className="mt-14 mb-24 col-span-full lg:col-start-2 lg:col-span-10">
              <HomepageHero />
            </section>
            <section className="mb-24 col-span-full lg:col-start-2 lg:col-span-10">
              <TerritoryDiscoverySection
                title="Discover Territories"
                tooltipText="Territories are common grounds for ideas. Time Points (t.p.) reflect the aggregated reading and writing activity in the Territory."
                sortBy={TerritorySortBy.MostTimePoints}
                take={6}
                initialTake={3}
                showSeeAllButton
                placement="homePage"
                showNewTerritoryButton
              />
            </section>
            {isRecommendedPostsFeatureEnabled && (
              <section className="mb-16 col-span-full lg:col-start-2 lg:col-span-10">
                <HomepageRecommendedPostsContainer />
              </section>
            )}
            <BelowTheFold>
              <section className="mt-7 mb-16 col-span-full lg:col-start-2 lg:col-span-10">
                <HomepagePromptsContainer />
              </section>
              <section className="mt-4 col-span-full lg:col-start-2 lg:col-span-10">
                <HomepagePostsContainer />
              </section>
            </BelowTheFold>
          </Container>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;

export const getServerSideProps = composeFp(
  behindFeatureFlag('homePage'),
  withApolloData(HomePage),
  logVisit,
)(async () => ({ props: {} }));

export const getHomePageUrl = (): string => `/`;
