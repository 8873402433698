import React, { useState } from 'react';
import { TPromptsData } from '../PromptsListSection/types';
import {
  PromptListPurpose,
  PROMPTS_SORTING_DROPDOWN_ITEMS,
  PromptsSortingDropdownKey,
} from '../PromptsListSection/constants';
import { getSortedPromptsQuery } from '../Prompt/queries/getSortedPromptsQuery';
import { ArticleType } from '../Article/types';
import { SectionHeader } from '../Section/SectionHeader';
import { SectionListResults } from '../SectionListResults/SectionListResults';
import { Section } from '../Section/Section';
import { PromptCardV2Container } from '../PromptCardV2/PromptCardV2Container';
import { SortingDropdown } from '../SortingDropdown/SortingDropdown';
import { PromptCardV2Loader } from '../PromptCardV2/PromptCardV2Loader';
import { useT2Query } from '../../hooks/useT2Query';

const HomepagePromptsContainer = () => {
  const PAGE_SIZE = 6;
  const [sortBy, setSortBy] = useState(PromptsSortingDropdownKey.RecommendedPrompts as string);
  const { data, loading, error, called, fetchMore } = useT2Query<{
    getSortedArticles: { articles: TPromptsData[]; articlesCount: number };
  }>(getSortedPromptsQuery, {
    variables: {
      skip: 0,
      take: PAGE_SIZE,
      where: { type: { equals: ArticleType.Prompt } },
      sortBy,
      infiniteScroll: true,
      placement: PromptListPurpose.latestPrompts,
    },
    notifyOnNetworkStatusChange: true,
  });

  const prompts = data?.getSortedArticles?.articles || [];
  const totalItems = data?.getSortedArticles?.articlesCount || 0;

  if (error) {
    return null;
  }

  const onSortByChange = (sortBy: string) => {
    setSortBy(sortBy);
  };

  const containerClassName = 'w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4';

  const results = (
    <section className={containerClassName}>
      {prompts?.map((prompt) => (
        <PromptCardV2Container
          key={prompt.id}
          prompt={prompt}
          isPinningEnabled={false}
          canManagePinnedArticles={false}
          showTerritory
        />
      ))}
    </section>
  );

  const resultsPlaceholder = (
    <section className={containerClassName}>
      {Array.from({ length: PAGE_SIZE }, (_, index) => (
        <PromptCardV2Loader key={index} />
      ))}
    </section>
  );

  return (
    <Section
      header={
        <SectionHeader
          title="Prompts"
          tooltipText="Create a prompt to spark new discussion, or respond to one with your own post!"
          leftSide={
            <SortingDropdown
              items={PROMPTS_SORTING_DROPDOWN_ITEMS}
              onSortByChange={onSortByChange}
              purpose={PromptListPurpose.latestPrompts}
              defaultSortBy={sortBy}
              dropdownLabelClassName="!text-sm !text-t2-neutral-grey08 !bg-t2-neutral-grey03"
            />
          }
        />
      }
    >
      <SectionListResults
        results={results}
        resultsPlaceholder={resultsPlaceholder}
        loading={loading}
        data={prompts}
        resultsCount={totalItems}
        take={PAGE_SIZE}
        called={called}
        fetchMore={fetchMore}
      />
    </Section>
  );
};
export default HomepagePromptsContainer;
