export const ARTICLE_CARD_FIELDS = `
  fragment ArticleCardFields on Article {
      id
      type
      slug
      title
      readTime
      shortDescription
      lastUpdateDate
      access
      status
      createdAt
      publishedAt
      author {
        id
        name
        publicAddress
        username
        profileImage {
          id
          publicUrl
            publicUrlTransformed
            _meta {
              public_id
              height
              width
              
            }
        }
      }
      coverImage {    
        id
        publicUrlTransformed 
        _meta {
            public_id
            height
            width
        }
      }
      totalTimePointsAccumulated
      commentsCount(where: { deactivated: { equals: false } })
      territory {
        id
        name
        tagColor
      }
  }
`;
