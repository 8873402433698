import React from 'react';
import { ArticleCardPurpose } from '../ArticleCard/constants';
import { PAGE_SIZE } from './constants';
import { Section } from '../Section/Section';
import { SectionHeader } from '../Section/SectionHeader';
import { SectionListResults } from '../SectionListResults/SectionListResults';
import { PostCardLoader } from '../PostCard/PostCardLoader';
import { PostCardContainer } from '../PostCard/PostCardContainer';
import { getArticlesCardData } from '../ArticleCard/services/getArticlesCardData';
import { useReactiveAuthenticatedUser } from '../Auth/hooks/useAuthenticatedUser';
import { useT2Query } from '../../hooks/useT2Query';
import { TRecommendedPostsQueryResponse } from './types';
import { getRecommendedPostsWithoutUserRelationsQuery } from './queries/getRecommendedPostsQuery';

export const HomepageRecommendedPostsContainer = () => {
  const { authenticatedUserId } = useReactiveAuthenticatedUser();
  const { data, loading, error, called } = useT2Query<TRecommendedPostsQueryResponse>(
    getRecommendedPostsWithoutUserRelationsQuery,
    {
      variables: { skip: 0, take: PAGE_SIZE, userId: authenticatedUserId },
    },
  );

  const articlesCount = data?.listing?.articlesCount || 0;
  const recommendedPosts = getArticlesCardData(data?.listing?.articles);

  if (error) {
    return null;
  }

  const containerClassName = 'w-full grid grid-cols-1 lg:grid-cols-2 gap-4';

  const results = (
    <section className={containerClassName}>
      {recommendedPosts?.map((article) => (
        <PostCardContainer
          key={article.id}
          article={article}
          forceMobileView
          showTerritory
          purpose={ArticleCardPurpose.recommendedPosts}
        />
      ))}
    </section>
  );

  const resultsPlaceholder = (
    <div>
      <section className={containerClassName}>
        {Array.from({ length: PAGE_SIZE }, (_, index) => (
          <PostCardLoader key={`loader-${index}`} forceMobileView />
        ))}
      </section>
    </div>
  );

  return (
    <Section header={<SectionHeader title="Recommended Posts" />}>
      <SectionListResults
        results={results}
        resultsPlaceholder={resultsPlaceholder}
        loading={loading}
        data={recommendedPosts}
        resultsCount={articlesCount}
        take={PAGE_SIZE}
        called={called}
      />
    </Section>
  );
};
